export const isView = (el) => {
    !el.classList.contains('is-view') ? el.classList.add('is-view') : null
}
export const isClass = (el, cls) => {
    let $root 
    if(el.classList.contains(cls)) {
        root = el
    } else {
        this.isClass()
    }
    
    return root 
}